@charset "UTF-8";

html, body {
}

header {
	display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;

    .container {
        width: 100%;
    }
}

.headroom {
    .navigation__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        transition: opacity 200ms ease, background-color 200ms ease;
    }
}

.headroom--top {
    .navigation__bg {
        opacity: 0;
    }
}

header.headroom--not-bottom.headroom--not-top.headroom--pinned {
    .navigation__bg {
        background-color: $white;
        opacity: 1;
    }
}

header.headroom--top.headroom--pinned {
    .navigation__bg {
        background-color: transparent;
        opacity: 0;
    }
}

// // Headroom
.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: translateY(0%);
    
    @include breakpoint(desktopMedium) {
        transform: translate(0%, 0%);
    }
}

.headroom--unpinned {
    transform: translateY(-100%);
    
    @include breakpoint(desktopMedium) {
        transform: translate(0%, -100%);
    }
}

.random-shape {
    display: none;

    @include breakpoint(tabletPortrait) {
        display: block;
    }
}

[hidden] {
	display: none;
}

[disabled] {
	cursor: not-allowed;
}
