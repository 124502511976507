.steps-holder {
    position: relative;
    z-index: 1;

    margin-bottom: 70px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.steps__title {
    margin-bottom: 8px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 16px;
    }
}

.steps__text {
    margin-bottom: 24px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 32px;
    }
}

.steps__step-container {
    position: relative;
    z-index: 1;
}

.steps__step-wrapper {
    display: flex;

    @include breakpoint(tabletLandscape) {
        flex-wrap: wrap;
    }
}

.steps__step {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    margin-right: 24px;

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (24px / 2) - (80px / 2));
    }
    
    @include breakpoint(tabletLandscape) {
        width: calc(25% - ((3 * 24px) / 4));
        margin-bottom: 36px;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.steps__step-number {
    margin-bottom: 16px;
    width: 32px;
    height: auto;
}

.steps__step-title {
    margin-bottom: 8px;
    font-weight: bold;
}

.steps__step-text {
    margin-bottom: 12px;
}

.steps__step-cta-holder {}

.steps__step-cta {
    display: flex;
    align-items: center;
    font-weight: bold;

    span {
        margin-left: 7px;
        margin-top: 1px;
        transition: transform 300ms ease;
    }

    &:hover {
        text-decoration: underline;

        span {
            transform: translateX(5px);
        }
    }
}

.steps__white-overlay {
    position: absolute;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 100%);
    width: 80px;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        display: none;
    }
}

.steps__pagination {
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(tabletLandscape) {
        display: none;
    }

    .swiper-pagination-bullet {
        margin: 0 4px;
        height: 6px;
        width: 6px;
        opacity: .2;
        background-color: $black;
        transition: opacity 200ms ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.steps__cta-holder {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 44px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-top: 8px;
    }
}

.steps__cta {
    &:nth-child(2) {
        display: none;

        @include breakpoint(tabletLandscape) {
            display: inline-block;
            margin-left: 24px;

            span {
                margin-left: 5px;
                transition: transform 300ms ease;
            }

            &:hover {
                span {
                    transform: translateX(5px);
                }
            }
        }
    }
}
