.projects-holder {
    position: relative;
    z-index: 1;
}

.projects__title {
    margin-bottom: 8px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 16px;
    }
}

.projects__text {
    margin-bottom: 24px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 32px;
    }
}

.projects__container {
    position: relative;
    z-index: 1;
}

.projects__wrapper {
    .projects-holder--multiple-rows & {
        @include breakpoint(tabletLandscape) {
            flex-wrap: wrap;
        }
    }
}

.projects__wrapper-no-slider {
    flex-direction: column;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.projects__project {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (24px / 2));
        margin-right: 24px;
    }

    @include breakpoint(tabletLandscape) {
        width: calc(33% - ((24px * 2) / 3));
        margin-right: 0;

        &:not(:last-child) {
            margin-right: 24px;
        }

        .projects-holder--multiple-rows & {
            margin-right: 24px;
            margin-bottom: 40px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3) {
                margin-bottom: 0;
            }
        }
    }

    .projects__wrapper-no-slider & {
        margin-bottom: 40px;

        @include breakpoint(tabletPortrait) {
            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @include breakpoint(tabletLandscape) {
            &:nth-child(2n) {
                margin-right: 24px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

.projects__project-image {
    margin-bottom: 24px;
    height: 200px;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.projects__project-title {
    font-weight: 700;
    margin-bottom: 16px;
}

.projects__project-text {
    margin-bottom: 16px;
}

.projects__project-cta-holder {
    display: flex;
    margin-top: auto;
}

.projects__project-cta {
    display: flex;
    align-items: center;
    font-weight: bold;

    span {
        margin-top: 2px;
        margin-left: 7px;
        transition: transform 300ms ease;
    }

    &:hover {
        text-decoration: underline;

        span {
            transform: translateX(5px);
        }
    }
}

.projects__pagination {
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(tabletLandscape) {
        display: none;
    }

    .swiper-pagination-bullet {
        margin: 0 4px;
        height: 6px;
        width: 6px;
        opacity: .2;
        background-color: $black;
        transition: opacity 200ms ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.projects__cta-holder {
    margin-top: 44px;
}
