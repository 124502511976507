.image-text-block-holder {
    position: relative;
    z-index: 1;
}

.image-text-block__container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    z-index: 1;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .image-text-block-holder--reverse & {
            flex-direction: row-reverse;
        }
    }
}

.image-text-block__text-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;

    .image-text-block-holder--reverse & {
        margin-bottom: 0;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (115px / 2));
        margin-bottom: 0;
    }

    @include breakpoint(tabletLandscape) {
        width: calc(50% - (184px / 2));
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (216px / 2));
    }
}

.image-text-block__step-holder {
    display: flex;
    flex-direction: column;

    .image-text-block__step {
        border-bottom: 1px solid rgba($dark-blue, 0.2);
        margin-bottom: 30px;

        &:nth-child(2) {
            margin-bottom: 0;
            border-bottom: none;

            @include breakpoint(tabletPortrait) {
                border-bottom: 1px solid rgba($dark-blue, 0.2);
            }
        }
    }
}

.image-text-block__section-name {
    margin-bottom: 16px;
    font-family: $primary-font-family-book;
}

.image-text-block__title {
    margin-bottom: 24px;

    @include breakpoint(desktopMedium) {
        margin-bottom: 32px;
    }
}

.image-text-block__text {
    margin-bottom: 40px;
}

.image-text-block__cta-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-text-block__cta {
    &:not(:last-child) {
        margin-right: 24px;
    }

    display: flex;
    flex-direction: row;

    span {
        margin-left: 8px;
        transition: transform 300ms ease;
    }

    &:hover {
        text-decoration: underline;

        span {
            transform: translateX(5px);
        }
    }
}

.image-text-block__image-holder {
    position: relative;
    display: flex;

    .image-text-block-holder--reverse & {
        margin-bottom: 24px;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (115px / 2));
        margin-bottom: 0;
    }

    @include breakpoint(tabletLandscape) {
        width: calc(50% - (184px / 2));
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (216px / 2));
    }
}

.image-text-block__image {
    width: 100%;
    height: auto;
}

.image-text-block__image-step {
    position: absolute;

    svg {
        display: block;
        width: 21px;
        height: auto;

        @include breakpoint(tabletPortrait) {
            width: 32px;
        }
    }
}

.image-text-block__image-step-first {
    top: 0;
    left: 0;
}

.image-text-block__image-step-second {
    bottom: 0;
    right: 0;
}
