.text-accordion-block-holder {
    position: relative;
    z-index: 1;
}

.text-accordion-block__container {
    display: flex;
    flex-direction: column;
    z-index: 1;

    .text-accordion-block-holder--reverse & {
        flex-direction: column-reverse;
    }

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.text-accordion-block__text-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;

    .text-accordion-block-holder--reverse & {
        margin-bottom: 0;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (88px / 2));
        margin-bottom: 0;
    }

    @include breakpoint(tabletLandscape) {
        width: calc(50% - (104px / 2));
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (120px / 2));
    }
}

.text-accordion-block__title {
    margin-bottom: 24px;

    @include breakpoint(desktopMedium) {
        margin-bottom: 32px;
    }
}

.text-accordion-block__text {
    margin-bottom: 40px;
}
.text-accordion-block__cta-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-accordion-block__cta {
    display: flex;
    flex-direction: row;
}

.text-accordion-block__cta-1 {
    margin-right: 24px;
}

.text-accordion-block__cta-2 {
    display: none;
    
    @include breakpoint(tabletLandscape) {
        display: block;

        span {
            display: inline-block;
            margin-left: 8px;
            transition: transform 300ms ease;
        }

        &:hover {
            text-decoration: underline;

            span {
                transform: translateX(5px);
            }
        }
    }
}

.text-accordion-block__accordion-list {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .text-accordion-block-holder--reverse & {
        margin-bottom: 24px;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (88px / 2));
        margin-bottom: 0;
        margin-top: 0;
    }

    @include breakpoint(tabletLandscape) {
        width: calc(50% - (104px / 2));
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (120px / 2));
    }
}

.text-accordion-block__accordion-item {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba($dark-blue, 0.08);

    &:not(:first-child) {
        margin-top: 12px;
    }
}

.text-accordion-block__accordion-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

    span {
        margin-left: auto;
        padding-left: 10px;
    }
}

.text-accordion-block__accordion-body {
    height: 0;
    overflow: hidden;

    >:first-child {
        padding-top: 16px;
    }

    p {
        font-size: 14px;
    }
}

.text-accordion-block__accordion-cta {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;

    span {
        margin-top: 2px;
        margin-left: 7px;
        transition: transform 300ms ease;
    }

    &:hover {
        span {
            transform: translateX(5px);
        }
    }
}
