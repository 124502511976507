.fullwidth-image-block-holder {
    margin-bottom: 40px;
    z-index: 1;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.fullwidth-image-block__container {
    height: 150px;
    width: 100%;

    @include breakpoint(tabletPortrait) {
        height: 250px;
    }

    @include breakpoint(tabletLandscape) {
        height: 384px;
    }
}

.fullwidth-image-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
