.testimonials-holder {
    position: relative;
    margin-bottom: 70px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.testimonials__title {
    margin-bottom: 8px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 16px;
    }
}

.testimonials__text {
    margin-bottom: 24px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 32px;
    }
}

.testimonials__container {
    position: relative;
    z-index: 1;
}

.testimonials__wrapper {
    display: flex;
}

.testimonials__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (88px / 2));
        margin-right: 88px;
    }

    @include breakpoint(tabletLandscape) {
        width: calc((100% / 2.5 - (104px * 2) / 3));
        margin-right: 104px;
    }

    @include breakpoint(desktopMedium) {
        width: calc((100% / 2.5 - (120px * 2) / 3));
        margin-right: 120px;
    }
}

.testimonials__item-text {
    margin-bottom: 32px;

    p {
        font-size: 18px;
    }
}

.testimonials__item-author-holder {
    display: flex;
    align-items: center;
}

.testimonials__item-image {
    margin-right: 20px;

    img {
        display: block;
        width: 64px;
        height: auto;
        border-radius: 50%;
    }
}

.testimonails__item-name-holder {
    display: flex;
    flex-direction: column;
}

.testimonials__item-name {
    h6 {
        font-weight: bold;
        font-size: 14px;
    }
}

.testimonials__item-description {
    p {
        font-size: 14px;
    }
}

.testimonials__white-overlay {
    display: none;

    @include breakpoint(tabletLandscape) {
        display: block;
        position: absolute;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 100%);
        width: 200px;
        height: 100%;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    @include breakpoint(desktopMedium) {
        width: 250px;
    }
}

.testimonials__pagination {
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
        margin: 0 4px;
        height: 6px;
        width: 6px;
        opacity: .2;
        background-color: $black;
        transition: opacity 200ms ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}
