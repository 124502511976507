.navigation-holder {
    display: flex;
    align-items: center;
    padding: 24px 0;
}

.navigation__logo-holder {
    position: relative;
    z-index: 10;
}

.navigation__logo {
    svg {
        height: 32px;
        width: auto;

        @include breakpoint(tabletLandscape) {
            height: 56px;
        }
    }
}

.navigation__button-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-left: auto;
    z-index: 10;
    cursor: pointer;

    @include breakpoint(tabletPortrait) {
        display: none;
    }
}

.navigation__button {
    height: 2px;
    width: 20px;
    background-color: $black;
}

.navigation__button--top {}

.navigation__button--middle {
    margin: 5px 0;
}

.navigation__button--bottom {}

.navigation__list-holder {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    z-index: 9;

    @include breakpoint(tabletPortrait) {
        position: relative;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &.active {
        display: flex;
    }
}

.navigation__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
    }
}

.navigation__item {
    position: relative;
    overflow: hidden;

    &:not(:first-child) {
        padding-top: 12px;
    }

    &:not(:last-child) {
        padding-bottom: 12px;
    }

    @include breakpoint(tabletPortrait) {
        padding-bottom: 3px;

        &:not(:first-child) {
            padding-top: 0;
            margin-left: 12px;
        }
    
        &:not(:last-child) {
            padding-bottom: 3px;
            margin-right: 12px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;
        transform: translateX(-100%);
        transition: transform 200ms ease;
    }

    &:hover {
        &:after {
            transform: translateX(0);
        }
    }
}

.navigation__item-active {
    &:after {
        transform: translateX(0);
    }

    a {
        font-weight: bold;
    }
}
