.contact-holder {
    position: relative;
    margin-bottom: 70px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.contact__container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
    }
}

.contact__block {
    width: 100%;

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (88px / 2));

        &:not(:last-child) {
            margin-right: 88px;
        }
    }
    
    @include breakpoint(tabletPortrait) {
        width: calc(50% - (104px / 2));

        &:not(:last-child) {
            margin-right: 104px;
        }
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (120px / 2));

        &:not(:last-child) {
            margin-right: 120px;
        }
    }
}

.contact__title {
    margin-bottom: 8px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 16px;
    }
}

.contact__text {
    margin-bottom: 24px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 32px;
    }
}

.contact__contact-block {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
    }
}

.contact__contact-address,
.contact__contact-phone-mail {
    width: 100%;

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (8px / 2));
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (40px / 2));
    }

    h4 {
        font-weight: bold;
        margin-bottom: 4px;
    }
}

.contact__contact-address {
    margin-top: 24px;

    @include breakpoint(tabletPortrait) {
        margin-top: 0;
    }
}

.contact__contact-address-text {
    p {
        &:first-child {
            margin-top: 0;
        }
    }
}

.contact__contact-phone-mail {
    display: flex;
    flex-direction: column;
}

.contact__contact-mail {
    margin-top: auto;
}

.contact__contact-form {
    margin-top: 50px;

    @include breakpoint(tabletPortrait) {
        margin-top: 0;
    }

    .wpcf7-form {
        p {
            margin-top: 0;
        }

        label {
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;
            font-size: 14px;
            font-family: $font;

            input,
            textarea {
                padding: 14px 16px;
                background-color: rgba($blue, 0.2);
                border-radius: 4px;
                border: none;
                margin-top: 6px;
                font-family: $font;
                font-size: 14px;

                @include breakpoint(desktopMedium) {
                    font-size: 16px;
                }

                &::placeholder {
                    color: rgba($dark-blue, 0.4);
                    font-weight: 400;
                    font-family: $font;
                }
            }
        }

        .wpcf7-list-item {
            label {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    margin-left: 12px;
                    font-size: 12px;
                    line-height: 20px;

                    @include breakpoint(desktopMedium) {
                        font-size: 14px;
                    }

                    a {
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 20px;

                        @include breakpoint(desktopMedium) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .wpcf7-submit {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border: none;
            background-color: $dark-blue;
            margin-top: 30px;
            border: 2px solid transparent;
            font-family: $primary-font-family-book;
            text-decoration: none;
            transition: color 300ms ease, background 300ms, border 300ms ease;
            @extend .button;
            @extend .heading-5;
        
            &:hover {
                background-color: transparent;
                color: $dark-blue;
                border: 2px solid $dark-blue;
                text-decoration: none;
            }
        }
    }
}

.wpcf7-form .wpcf7-radio label {
    position: relative;
    cursor: pointer;
}

.wpcf7-form .wpcf7-radio input[type=radio] {
    position: relative;
    visibility: hidden;
}

.wpcf7-form .wpcf7-radio input[type=radio] + span {
  /*   border: 3px solid red;  */
}

.wpcf7-form .wpcf7-radio input[type=radio] + span:before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 18px;
    width: 18px;
    top: 0px;
    // border: 2px solid #222;
}

.wpcf7-form .wpcf7-radio input[type=radio] + span:after {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 6px;
    left: 6px;
    visibility: hidden;
}

.wpcf7-form .wpcf7-radio input[type=radio]:checked + span:before {
    background: transparent;
  /*     border-color: red; */
}

.wpcf7-form .wpcf7-radio input[type=radio]:checked + span:after {
    // background: #222;
    visibility: visible;
}

.wpcf7-form .wpcf7-acceptance label {
    position: relative;
    cursor: pointer;
}

.wpcf7-form .wpcf7-acceptance input[type=checkbox] {
    position: relative;
    visibility: hidden;
}

.wpcf7-form .wpcf7-acceptance input[type=checkbox] + span {}

.wpcf7-form .wpcf7-acceptance input[type=checkbox] + span:before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 4px;
    height: 24px;
    width: 24px;
    top: 0px;
    left: 0;
    background-color: rgba($dark-blue, 0.2);
}

.wpcf7-form .wpcf7-acceptance input[type=checkbox] + span:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 13px;
    background: $dark-blue;
    width: 2px;
    height: 2px;
    box-shadow: -3px 0 0 $dark-blue, -2px 0 0 $dark-blue, 2px 0 0 $dark-blue, 4px 0 0 $dark-blue,
        4px -2px 0 $dark-blue, 4px -4px 0 $dark-blue, 4px -6px 0 $dark-blue, 4px -8px 0 $dark-blue,
        4px -10px 0 $dark-blue, 4px -12px 0 $dark-blue;
    transform: rotate(45deg);
    visibility: hidden;
}

.wpcf7-form .wpcf7-acceptance input[type=checkbox]:checked + span:before {
    // background-color: rgba(15,34,53,.2);
}

.wpcf7-form .wpcf7-acceptance input[type=checkbox]:checked + span:after {
    visibility: visible;
}

.screen-reader-response {
    position: absolute;
    visibility: hidden;
}

.wpcf7-response-output {
    margin-top: 10px;
}
