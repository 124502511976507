.content-holder {
    margin-bottom: 40px;
    z-index: 1;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.content__container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
    }
}

.content__highlighted-holder {
    margin: 20px auto;
    padding-top: 20px;
    border-top: 1px solid rgba($dark-blue, 0.2);
    max-width: 420px;

    @include breakpoint(tabletPortrait) {
        margin: 0;
        padding: 0;
        border: none;
        max-width: 168px;
    }

    @include breakpoint(tabletLandscape) {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.content__highlighted-title {
    margin-bottom: 8px;
    font-weight: bold;
}

.content__highlighted-text {
    font-size: 14px;
    line-height: 20px;
}

.content__content {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(tabletLandscape) {
        max-width: 540px;
    }
}
