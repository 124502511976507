.button {
    display: inline-flex;
    font-size: 16px;
    padding: 12px 17px;
    background-color: $dark-blue;
    color: $white;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid transparent;
    font-family: $primary-font-family-book;
    text-decoration: none!important;
    transition: color 300ms ease, background 300ms, border 300ms ease;

    &:hover {
        background-color: transparent;
        color: $dark-blue;
        text-decoration: none!important;
        border: 2px solid $dark-blue;
    }
}

.button--full-width {
    display: flex;
}

.image-background-shape {
    @include breakpoint(tabletPortrait) {
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -150px;
            transform: translateY(-50%) rotate(45deg);
            width: 105px;
            height: 105px;
            background-color: rgba($beige, 0.2);
            box-shadow: 105px 0px 0px rgba($beige, 0.2),
                        105px -105px 0 rgba($beige, 0.2);
            z-index: -1;

            .image-text-block-holder--reverse & {
                left: unset;
                right: 0px;
            }
        }
    }

    @include breakpoint(desktopMedium) {
        &:before {
            left: -180px;
            width: 125px;
            height: 125px;
            background-color: rgba($beige, 0.2);
            box-shadow: 125px 0px 0px rgba($beige, 0.2),
                        125px -125px 0 rgba($beige, 0.2);
        }
    }

    .image-block__container & {
        @include breakpoint(tabletPortrait) {
            &:before {
                left: unset;
                right: 330px;
            }
        }

        @include breakpoint(tabletLandscape) {
            &:before {
                right: 420px;
            }
        }
    }
}

.--dark-blue {
    color: $dark-blue;
}

.--blue {
    color: $blue;
}

.--red {
    color: $red;
}

.--green {
    color: $green;
}

.--beige {
    color: $beige;
}

.--brown {
    color: $brown;
}

.--yellow {
    color: $yellow;
}

.--bg-dark-blue {
    background-color: $dark-blue;
}

.--bg-blue {
    background-color: $blue;
}

.--bg-red {
    background-color: $red;
}

.--bg-green {
    background-color: $green;
}

.--bg-beige {
    background-color: $beige;
}

.--bg-brown {
    background-color: $brown;
}

.--bg-yellow {
    background-color: $yellow;
}
