@mixin breakpoint($point) {
    @if $point == mobile {
        @media (min-width: $mobile) {
            @content;
        }
    } @else if $point == mobileMedium {
        @media (min-width: $mobileMedium) {
            @content;
        }
    } @else if $point == mobileLarge {
        @media (min-width: $mobileLarge) {
            @content;
        }
    } @else if $point == tabletPortrait {
        @media (min-width: $tabletPortrait) {
            @content;
        }
    } @else if $point == tabletLandscape {
        @media (min-width: $tabletLandscape) {
            @content;
        }
    } @else if $point == desktop {
        @media (min-width: $desktop) {
            @content;
        }
    } @else if $point == desktopMedium {
        @media (min-width: $desktopMedium) {
            @content;
        }
    } @else if $point == desktopLarge {
        @media (min-width: $desktopLarge) {
            @content;
        }
    }
}
