.image-block-holder {
    position: relative;
    margin-bottom: 40px;
    z-index: 1;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.image-block__container {
    position: relative;
    max-width: 420px;
    margin: 0 auto;
    padding-left: 168px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        max-width: 540px;
        padding: 0;
    }
}

.image-block__image-holder {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.image-block__image {
    width: 100%;
    max-width: 384px;

    @include breakpoint(tabletPortrait) {
        width: calc(100% / 3 * 2);
    }
}
