.image-text-block__shape,
.cards__shape,
.steps__shape,
.projects__shape,
.text-accordion__shape,
.testimonials__shape,
.contact__shape,
.image-block__shape {
    position: absolute;
    z-index: -1;
}

.navigation__shape {
    position: absolute;
    top: 0;
    left: 20vw;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        left: 30.8vw;
    }
}

.image-text-block__shape-1 {
    right: 43.4vw;

    @include breakpoint(desktopMedium) {
        right: 520px;
    }

    .image-text-block-holder--reverse & {
        left: 43.4vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 520px;
            right: unset;
        }
    }
}

.image-text-block__shape-2 {
    right: 4.3vw;

    @include breakpoint(desktopMedium) {
        right: 0;
    }

    .image-text-block-holder--reverse & {
        left: 4.3vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 0;
            right: unset;
        }
    }
}

.image-text-block__shape-3 {
    top: 81px;
    right: 41.5vw;

    @include breakpoint(desktopMedium) {
        top: 96px;
        right: 464px;
    }

    .image-text-block-holder--reverse & {
        top: 81px;
        left: 41.5vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            top: 96px;
            left: 464px;
            right: unset;
        }
    }
}

.image-text-block__shape-4 {
    bottom: 81px;
    right: 43vw;

    @include breakpoint(desktopMedium) {
        bottom: 96px;
        right: 489px;
    }

    .image-text-block-holder--reverse & {
        bottom: 81px;
        left: 43vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            bottom: 96px;
            left: 489px;
            right: unset;
        }
    }
}

.image-text-block__shape-5 {
    bottom: 0;
    right: 43.4vw;

    @include breakpoint(desktopMedium) {
        right: 520px;
    }

    .image-text-block-holder--reverse & {
        bottom: 0;
        left: 43.4vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 520px;
            right: unset;
        }
    }
}

.image-text-block__shape-6 {
    right: 4.3vw;
    bottom: 0;

    @include breakpoint(desktopMedium) {
        right: 0;
    }

    .image-text-block-holder--reverse & {
        left: 4.3vw;
        right: unset;
        bottom: 0;

        @include breakpoint(desktopMedium) {
            right: unset;
            left: 0;
        }
    }
}

.image-text-block__shape-7 {
    display: none;
    
    @include breakpoint(tabletLandscape) {
        display: block;
        top: -44px;
        left: 4.3vw;
    }

    @include breakpoint(desktopMedium) {
        left: 0;
    }

    .image-text-block-holder--reverse & {
        display: none;
    
        @include breakpoint(tabletLandscape) {
            display: block;
            top: -44px;
            left: unset;
            right: 4.3vw;
        }

        @include breakpoint(desktopMedium) {
            left: unset;
            right: 0;
        }
    }
}

.image-text-block__shape-8 {
    top: -10px;
    left: 33.3vw;

    @include breakpoint(desktopMedium) {
        left: 480px;
    }

    .image-text-block-holder--reverse & {
        top: -10px;
        left: unset;
        right: 33.3vw;

        @include breakpoint(desktopMedium) {
            left: unset;
            right: 480px;
        }
    }
}

.image-text-block__shape-9 {
    top: -23px;
    right: 8.5vw;

    @include breakpoint(desktopMedium) {
        right: 96px;
    }

    .image-text-block-holder--reverse & {
        top: -23px;
        left: 8.5vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 96px;
            right: unset;
        }
    }
}

.image-text-block__shape-10 {
    bottom: 56px;
    left: 44vw;

    @include breakpoint(tabletLandscape) {
        left: 49vw;
    }

    @include breakpoint(desktopMedium) {
        left: 374px;
    }

    .image-text-block-holder--reverse & {
        bottom: 56px;
        left: unset;
        right: 44vw;

        @include breakpoint(tabletLandscape) {
            left: unset;
            right: 49vw;
        }

        @include breakpoint(desktopMedium) {
            left: unset;
            right: 374px;
        }
    }
}

.image-text-block__shape-11 {
    display: none;

    @include breakpoint(tabletLandscape) {
        display: block;
        bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
        left: 12.1vw;
    }


    @include breakpoint(desktopMedium) {
        left: 96px;
        bottom: -56px;
    }

    .image-text-block-holder--reverse & {
        display: none;

        @include breakpoint(tabletLandscape) {
            display: block;
            bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
            left: unset;
            right: 12.1vw;
        }


        @include breakpoint(desktopMedium) {
            left: unset;
            right: 96px;
            bottom: -56px;
        }
    }
}

.image-text-block__shape-12 {
    right: 43.4vw;
    bottom: -10px;

    @include breakpoint(tabletLandscape) {
        bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
    }

    @include breakpoint(desktopMedium) {
        right: 480px;
        bottom: -56px;
    }

    .image-text-block-holder--reverse & {
        left: 43.4vw;
        right: unset;
        bottom: -10px;

        @include breakpoint(tabletLandscape) {
            bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
        }

        @include breakpoint(desktopMedium) {
            left: 480px;
            right: unset;
            bottom: -56px;
        }
    }
}

.image-text-block__shape-13 {
    right: 4.3vw;
    bottom: -10px;

    @include breakpoint(tabletLandscape) {
        bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
    }

    @include breakpoint(desktopMedium) {
        right: 0;
        bottom: -56px;
    }

    .image-text-block-holder--reverse & {
        left: 4.3vw;
        right: unset;
        bottom: -10px;

        @include breakpoint(tabletLandscape) {
            bottom: calc(-56px + (-16 - -56) * ((100vw - 1024px) / (1440 - 1024)));
        }

        @include breakpoint(desktopMedium) {
            left: 0;
            right: unset;
            bottom: -56px;
        }
    }
}

.cards__shape-1 {
    top: -36px;
    right: 27.7vw;

    @include breakpoint(desktopMedium) {
        right: 288px;
    }
}

.cards__shape-2 {
    top: 32px;
    right: 4.3vw;

    @include breakpoint(desktopMedium) {
        right: 0;
    }
}

.cards__shape-3 {
    bottom: -56px;
    left: 4.3vw;

    @include breakpoint(desktopMedium) {
        left: 0;
    }
}

.steps__shape-1 {
    left: 41.5vw;
    
    @include breakpoint(tabletLandscape) {
        left: 35.5vw;
    }

    @include breakpoint(desktopMedium) {
        left: 456px;
    }
}

.steps__shape-2 {
    right: 12.1vw;

    @include breakpoint(desktopMedium) {
        right: 96px;
    }
}

.steps__shape-3 {
    bottom: 0;
    right: 27.7vw;

    @include breakpoint(desktopMedium) {
        right: 288px;
    }
}

.projects__shape-1 {
    top: -50px;
    left: 4.3vw;

    @include breakpoint(desktopMedium) {
        left: 0;
    }
}

.projects__shape-2 {
    top: -50px;
    right: 12.1vw;

    @include breakpoint(desktopMedium) {
        right: 96px;
    }
}

.projects__shape-3 {
    top: 0;
    right: 25.5vw;

    @include breakpoint(tabletLandscape) {
        right: 35.5vw;
    }

    @include breakpoint(desktopMedium) {
        right: 384px;
    }
}

.projects__shape-4 {
    bottom: 0;
    right: 4.3vw;

    @include breakpoint(desktopMedium) {
        right: 0;
    }
}

.text-accordion__shape-1 {
    display: none;

    @include breakpoint(tabletLandscape) {
        top: -67px;
        left: 4.3vw;
    }

    @include breakpoint(desktopMedium) {
        left: 0;
    }
}

.text-accordion__shape-2 {
    top: -50px;
    left: 43.4vw;

    @include breakpoint(desktopMedium) {
        left: 480px;
    }
}

.testimonials__shape-1 {
    top: -75px;
    left: 25vw;

    @include breakpoint(tabletLandscape) {
        left: 19.9vw;
    }

    @include breakpoint(desktopMedium) {
        left: 192px;
    }
}

.testimonials__shape-2 {
    top: -50px;
    right: 4.3vw;

    @include breakpoint(desktopMedium) {
        right: 0;
    }
}

.testimonials__shape-3 {
    bottom: -50px;
    right: 19.9vw;

    @include breakpoint(desktopMedium) {
        right: 192px;
    }
}

.contact__shape-1 {
    display: none;

    @include breakpoint(tabletLandscape) {
        display: block;
        top: -75px;
        left: 27.7vw;
    }

    @include breakpoint(desktopMedium) {
        left: 288px;
    }
}

.contact__shape-2 {
    top: -50px;
    right: 4.3vw;

    @include breakpoint(desktopMedium) {
        right: 0;
    }
}

.contact__shape-3 {
    bottom: -75px;
    left: 4.3vw;

    @include breakpoint(desktopMedium) {
        left: 0;
    }
}

.contact__shape-4 {
    bottom: -50px;
    right: 43.4vw;

    @include breakpoint(desktopMedium) {
        right: 430px;
    }
}

.image-block__shape-1 {
    left: 25vw;

    @include breakpoint(desktopMedium) {
        left: 289px;
    }

    .image-text-block-holder--reverse & {
        left: unset;
        right: 25vw;

        @include breakpoint(desktopMedium) {
            left: unset;
            right: 289px;
        }
    }
}

.image-block__shape-2 {
    right: 23.5vw;

    @include breakpoint(desktopMedium) {
        right: 287px;
    }

    .image-text-block-holder--reverse & {
        left: 23.5vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 287px;
            right: unset;
        }
    }
}

.image-block__shape-3 {
    top: 81px;
    left: 32.5vw;

    @include breakpoint(desktopMedium) {
        top: 96px;
        left: 385px;
    }

    .image-text-block-holder--reverse & {}
}

.image-block__shape-4 {
    bottom: 81px;
    left: 30vw;

    @include breakpoint(desktopMedium) {
        bottom: 96px;
        left: 361px;
    }

    .image-text-block-holder--reverse & {
        bottom: 81px;
        left: unset;
        right: 30vw;

        @include breakpoint(desktopMedium) {
            bottom: 96px;
            left: unset;
            right: 361px;
        }
    }
}

.image-block__shape-5 {
    bottom: 0;
    left: 23vw;

    @include breakpoint(desktopMedium) {
        left: 289px;
    }

    .image-text-block-holder--reverse & {
        bottom: 0;
        left: unset;
        right: 23vw;

        @include breakpoint(desktopMedium) {
            left: unset;
            right: 289px;
        }
    }
}

.image-block__shape-6 {
    bottom: 0;
    right: 23.5vw;

    @include breakpoint(desktopMedium) {
        right: 287px;
    }

    .image-text-block-holder--reverse & {
        bottom: 0;
        left: 23.5vw;
        right: unset;

        @include breakpoint(desktopMedium) {
            left: 287px;
            right: unset;
        }
    }
}
