.cards-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.cards__title {
    margin-bottom: 8px;

    @include breakpoint(tabletPortrait) {
        padding-right: 80px;
    }

    @include breakpoint(tabletLandscape) {
        margin-bottom: 16px;
    }
}

.cards__text {
    margin-bottom: 24px;

    @include breakpoint(tabletPortrait) {
        padding-right: 80px;
    }

    @include breakpoint(tabletLandscape) {
        margin-bottom: 32px;
    }
}

.cards__cards-holder {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        justify-content: space-between;
    }
}

.cards__container {
    display: flex;
    width: 100%;
}

.cards__wrapper {
    display: flex;
    width: 100%;
}

.cards__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 4px;
    height: auto;
    box-sizing: border-box;

    &:not(:last-child) {
        margin-right: 24px;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - 24px);

        &:not(:last-child) {
            margin-right: 24px;
            margin-left: 7px;
        }
    }

    @include breakpoint(tabletLandscape) {
        // width: calc(33% - (48px / 3 + 48px));
        flex: 1;

        &:not(:last-child) {
            margin-right: 24px;
            margin-left: 0;
        }
    }
}

.cards__card--red {
    background-color: rgba($red, 0.2);
}

.cards__card--green {
    background-color: rgba($green, 0.2);
}

.cards__card--blue {
    background-color: rgba($blue, 0.2);
}

.cards__card--yellow {
    background-color: rgba($yellow, 0.2);
}

.cards__card-logo-holder {
    margin-bottom: 24px;
}

.cards__card-header {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba($dark-blue, 0.1);
}

.cards__card-body {
    margin-bottom: 32px;
}

.cards__card-cta-holder {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.cards__card-cta {
    &:not(:last-child) {
        margin-right: 24px;
    }
}

.cards__pagination {
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(tabletLandscape) {
        display: none;
    }

    .swiper-pagination-bullet {
        margin: 0 4px;
        height: 6px;
        width: 6px;
        opacity: .2;
        background-color: $black;
        transition: opacity 200ms ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}
