// fonts
$font: 'geomanistregular';

// colors
$dark-blue: #0F2235;
$blue: #4878BB;
$red: #E73731;
$green: #4F9834;
$beige: #FCD2A1;
$brown: #946131;
$yellow: #FBB900;

$white: #ffffff;
$black: #000000;
$gray: #cccccc;

// Breakpoints
$mobile: 320px;
$mobileMedium: 480px;
$mobileLarge: 600px;
$tabletPortrait: 768px;
$tabletLandscape: 1024px;
$desktop: 1280px;
$desktopMedium: 1440px;
$desktopLarge: 1600px;
$hd: 1920px;
