main {
    padding-top: 96px;

    @include breakpoint(tabletLandscape) {
        padding-top: 216px;
    }
}

section {
    margin-bottom: 40px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.container {
    padding: 0 13px;

    @include breakpoint(tabletPortrait) {
        padding: 0 28px;
    }

    @include breakpoint(tabletLandscape) {
        padding: 0 44px;
        max-width: 1128px;
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(desktopMedium) {
        padding: 0;
        width: 100%;
    }
}
