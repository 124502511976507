@charset "UTF-8";

// Variables

$geomanist: 'geomanistregular';
$geomanist-book: 'geomanistbook';
$geomanist-bold: 'geomanistbold';

// Actual css

@font-face {
    font-family: $geomanist;
    src: url('./assets/fonts/geomanist/geomanist-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $geomanist-book;
    src: url('./assets/fonts/geomanist/geomanist-book-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $geomanist-bold;
    src: url('./assets/fonts/geomanist/geomanist-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
