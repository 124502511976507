@charset "UTF-8";

@import '../../assets/fonts/fonts';

$primary-font-family: $geomanist;
$primary-font-family-book: $geomanist-book;
$primary-font-family-bold: $geomanist-bold;

$headings-uppercase: false;

:root {
    font-size: 62.5%;
}

html, body {
    font-family: $primary-font-family;
    color: $dark-blue;
    line-height: 1.2;
    font-size: 1.6rem;

    @include breakpoint($tabletPortrait) {
        line-height: 1.5;
    }

}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: $primary-font-family;

    @if ($headings-uppercase) {
        text-transform: uppercase;
    }
}

.heading-display {
    font-size: 32px;
    line-height: 38px;
    font-family: $primary-font-family-bold;

    @include breakpoint(tabletLandscape) {
        font-size: 40px;
        line-height: 48px;
    }

    @include breakpoint(desktopMedium) {
        font-size: 56px;
        line-height: 60px;
    }
}

.heading-1,
.heading-2 {
    font-family: $primary-font-family-bold;
    font-size: 32px;
    line-height: 38px;

    @include breakpoint(desktopMedium) {
        font-size: 40px;
        line-height: 48px;
    }
}

.heading-3 {
    font-family: $primary-font-family-bold;
    font-size: 18px;
    line-height: 28px;

    @include breakpoint(desktopMedium) {
        font-size: 24px;
        line-height: 32px;
    }
}

.heading-4 {
    font-family: $primary-font-family-bold;
    font-size: 18px;
    line-height: 28px;
}

.heading-5 {
    font-size: 16px;
    line-height: 24px;
}

.heading-6 {
    font-size: 14px;
    line-height: 20px;
}

p, .p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:not(:first-child) {
        margin-top: 16px;
    }

    @include breakpoint(desktopMedium) {
        font-size: 18px;
        line-height: 28px;

        &:not(:first-child) {
            margin-top: 18px;
        }
    }
}

a {
    font-size: 1.6rem;
    text-decoration: none;
    font-family: $primary-font-family;
    cursor: pointer;
    transition: color 300ms ease;
    color: $dark-blue;

    &:hover,
    &:focus {
        color: lighten($dark-blue, 10%);
    }

}

em, i {
    font-family: $primary-font-family;
    font-style: italic;
}

b, strong {
    font-family: $primary-font-family-bold;
    font-weight: bold;
}

mark, del, ins {
    font-size: 1.6rem;
}

small, sub, sup {
    font-size: 1.2rem;
}

blockquote {
    font-size: 1.6rem;
}

// code, samp, kbd {
//     font-size: 1.6rem;
//     // font-family: code fontje;
// }

// var {
//     font-size: 1.6rem;
//     font-style: italic;
//     // font-family: sier fontje;
// }
