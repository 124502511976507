.footer-holder {
    position: relative;
    z-index: 1;

    div,
    p,
    a {
        font-size: 14px;
        line-height: 16px;
    }

    p {
        margin-top: 0;
    }

    h5 {
        font-weight: bold;
        margin-bottom: 8px;
    }
}

.footer__main-holder {}

.footer__container {
    display: flex;
    flex-direction: column;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.footer__logo-holder {
    margin-bottom: 56px;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 0;
    }

    svg {
        display: block;
        width: 108px;
        height: auto;
    }
}

.footer__nav-holder {
    display: flex;
    flex-direction: column;
}

.footer__nav {
    display: flex;
    margin-bottom: 56px;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 0;
    }
}

.footer__nav-list {

    &:nth-child(1) {
        margin-right: 34px;
    }

    @include breakpoint(tabletPortrait) {
        width: calc(50% - (40px / 2));

        &:nth-child(1) {
            margin-right: 40px;
        }
    }

    @include breakpoint(tabletLandscape) {
        width: calc(50% - (80px / 2));

        &:nth-child(1) {
            margin-right: 80px;
        }
    }

    @include breakpoint(desktopMedium) {
        width: calc(50% - (120px / 2));

        &:nth-child(1) {
            margin-right: 120px;
        }
    }
}

.footer__nav-item {

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &:hover {
        a {
            &:after {
                transform: translateX(0);
            }
        }
    }

    a {
        display: inline-block;
        position: relative;
        padding-bottom: 3px;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $dark-blue;
            transform: translateX(-100%);
            transition: transform 300ms ease;
        }
    }
}

.footer__contact-holder {
    display: flex;
    flex-direction: column;
}

.footer__contact-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(tabletPortrait) {
        flex-direction: row;
    }
}

.footer__contact-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;

    @include breakpoint(tabletPortrait) {
        margin-right: 40px;
        margin-bottom: 0;
    }

    @include breakpoint(tabletLandscape) {
        margin-right: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-right: 120px;
    }

    a {
        position: relative;
        display: inline-block;
        align-self: flex-start;
        margin-bottom: 5px;
        padding-bottom: 3px;
        overflow: hidden;

        &:hover {
            &:after {
                transform: translateX(0);
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $dark-blue;
            transform: translateX(-100%);
            transition: transform 300ms ease;
        }
    }
}

.footer__contact-business {
    display: flex;
    flex-direction: column;

    p {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}

.footer__credits {
    position: relative;
    background-color: $dark-blue;
    color: rgba($white, 0.4);
    font-size: 14px;
    padding: 16px 0;
    margin-top: 52px;

    @include breakpoint(tabletPortrait) {
        margin-top: 64px;
    }
}

.footer__credits-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(tabletLandscape) {
        flex-direction: row;
        white-space: nowrap;
    }
}

.footer__credits-copyright {
    margin-bottom: 16px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 0;
    }
}

.footer__credits-design {
    margin-bottom: 2px;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 0;
        margin-left: 5px;
    }

    a {
        color: rgba($white, 0.4);

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__credits-dev {
    @include breakpoint(tabletLandscape) {
        margin-left: 5px;
    }

    a {
        color: rgba($white, 0.4);

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__credits-pages {
    display: flex;
    margin-top: 26px;

    @include breakpoint(tabletLandscape) {
        margin-top: 0;
        margin-left: auto;
    }

    a {
        color: rgba($white, 0.4);

        &:nth-child(1) {
            margin-right: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
