.title-block-holder {
    margin-bottom: 40px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
    }
}

.title-block__container {}

.title-block__section-name {
    margin-bottom: 16px;
    font-family: $primary-font-family-book;
}

.title-block__title {
    width: 100%;
    margin-bottom: 16px;

    @include breakpoint(tabletPortrait) {
        width: 50%;
    }
}

.title-block__meta {}

.title-block__meta-date {
    margin-bottom: 4px;

    @include breakpoint(tabletPortrait) {
        margin-bottom: 0;
    }
}

.title-block__meta-container {
    @include breakpoint(tabletPortrait) {
        display: flex;
        align-items: center;
    }
}

.title-block__meta-author {
    padding-bottom: 28px;
    border-bottom: 1px solid rgba($dark-blue, 0.2);

    @include breakpoint(tabletPortrait) {
        padding-bottom: 0;
        border-bottom: none;
        margin-right: auto;
    }
}

.title-block__meta-share {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @include breakpoint(tabletPortrait) {
        margin-top: 0;
    }
}

.title-block__meta-share-text {
    margin-right: auto;
    
    @include breakpoint(tabletPortrait) {
        margin-right: 16px;
    }
}

.title-block__meta-share-fb {
    margin-right: 16px;
}

.title-block__meta-share-linkedin {
    margin-right: 16px;
}

.title-block__meta-share-copy {
    cursor: pointer;
}

#to-be-copied-link {
    position: absolute;
    left: -9999px;
}
