.usp-holder {
    position: relative;
    display: flex;
    margin-bottom: 70px;
    z-index: 1;

    @include breakpoint(tabletLandscape) {
        margin-bottom: 80px;
    }

    @include breakpoint(desktopMedium) {
        margin-bottom: 160px;
        justify-content: center;
    }
}

.usp__container {
    margin: 0;
    width: 100%;
}

.usp__wrapper {
    display: flex;
}

.usp__slide {
    display: flex;
    align-items: center;
    width: auto;

    &:not(:last-child) {
        margin-right: 25px;
    }

    @include breakpoint(tabletLandscape) {
        flex: 1;
    }

    @include breakpoint(desktopMedium) {
        &:not(:last-child) {
            margin-right: 40px;
        }
    }

    span {
        margin-right: 20px;
    }
}

.usp__pagination {
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(tabletLandscape) {
        display: none;
    }

    .swiper-pagination-bullet {
        margin: 0 4px;
        height: 6px;
        width: 6px;
        opacity: .2;
        background-color: $black;
        transition: opacity 200ms ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}
